// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import Rails from '@rails/ujs';
import "trix"
import "@rails/actiontext"

Rails.start();
import { Turbo } from "@hotwired/turbo-rails"
import Carousel from 'bootstrap/js/dist/carousel';
import Offcanvas from 'bootstrap/js/dist/offcanvas';
import Toast from 'bootstrap/js/dist/toast';
import Alert from 'bootstrap/js/dist/alert';
import ScrollSpy from 'bootstrap/js/dist/scrollspy';
window.toast = Toast;

var scrollTrigger = document.querySelectorAll('.scroll-trigger');

var headerOffcanvas = document.querySelector('.offcanvas')
var bsOffcanvas = new Offcanvas(headerOffcanvas)

for (var i = 0; i < scrollTrigger.length; i++) {
  scrollTrigger[i].addEventListener('click', function(event) {
    scrollToSection(event)
  });
};

function scrollToSection(event) {
  event.preventDefault()
  bsOffcanvas.hide()

  var id = event.currentTarget.getAttribute("href")
  if (id.startsWith('/')) {
    window.location = id;
  }
  var element = document.querySelector(id);
  var headerOffset = 80;
  var elementPosition = element.getBoundingClientRect().top;
  var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
  });
};

var video_player = document.querySelector('#backgroundVideo');
if (document.body.contains(video_player)) {
  document.querySelector('#backgroundVideo').playbackRate = 0.7;
}

var alertList = document.querySelectorAll('.alert')
alertList.forEach(function (alert) {
  new Alert(alert)
})

const appHeight = () => document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
window.addEventListener('resize', appHeight)
appHeight()

// Adding a new custom action to Turbo Stream
// to use the new action to redirect to a new page from controller you have two options:
// 1. Rendering inline in a controller
//    => `render turbo_stream: turbo_stream.redirect(your_path)`
// 2. Using an *.turbo_stream.haml file
//    => `<turbo-stream action="redirect" url="<%= your_path %>"></turbo-stream>`
// 3. Using an *.turbo_stream.erb file
//    => <%= turbo_stream.redirect(your_path) %>
// NOTE: the helper app/helpers/turbo_streams/redirect_helper.rb must exist
Turbo.StreamActions.redirect = function() {
  const url = this.getAttribute('url') || '/'
  // action: 'advance' will push a new entry to the history stack of the browser (like a redirect)
  Turbo.visit(url, { frame: '_top', action: 'advance' })
}
import "trix"
import "@rails/actiontext"
