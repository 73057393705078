// app/javascript/controllers/nested_form_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "template", "item" ]

  add(event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.element.insertBefore(
      document.createRange().createContextualFragment(content),
      event.target.parentElement
    )
  }

  remove(event) {
    event.preventDefault()

    const item = event.target.closest('[data-nested-form-target="item"]')

    // If the item has a destroy input, mark it for destruction
    const destroyInput = item.querySelector('input[name*="_destroy"]')
    if (destroyInput) {
      destroyInput.value = '1'
      item.style.display = 'none'
    } else {
      item.remove()
    }
  }
}
